import React, { useState ,useEffect,Fragment,} from "react";
import banner from "../../assets/images/banner.png";
import hover from "../../assets/images/building2.webp";
import cleaninservice from "../../assets/images/building3.webp";
import visionLogo from "../../assets/icons/eye.svg";
import teamholder from "../../assets/icons/teamholder.svg";
import builderLogo from "../../assets/icons/builder.svg";
import { FaChevronRight } from "react-icons/fa";
import { IoCheckmarkCircle } from "react-icons/io5";
import { GrUserSettings } from "react-icons/gr";
import { GiDelicatePerfume } from "react-icons/gi";
import { FaStudiovinari } from "react-icons/fa6";
import { FaThumbsUp } from "react-icons/fa";
import serviceBg from "../../assets/images/disinfection-worker-spraying-subway-staircase-due-covid19-pandemic.webp";
import { Link } from "react-router-dom";
import { TiChevronRight } from "react-icons/ti";
import BookingForm from "../../components/booking_form";
import slide1 from "../../assets/images/building_slide.jpg";
import slide2 from "../../assets/images/building_slide2.jpg";
import slide3 from "../../assets/images/building_slide1.jpg";

function BuildingMaintenance() {
  const serviceicons = [
    {
      icon: <GrUserSettings className="text-white text-xl" />,
      name: "Experienced Staff",
      description: "Skilled, Knowledgeable, Professional, Efficient, Reliable",
    },
    {
      icon: <GiDelicatePerfume className="text-white text-xl" />,
      name: "Fast Service ",
      description: "Quick, Efficient, Prompt, Expedient, Timely",
    },
    {
      icon: <FaStudiovinari className="text-white text-xl" />,
      name: "Natural products ",
      description: "Eco-friendly, Safe, Organic, Non-toxic, Sustainable",
    },
    {
      icon: <FaThumbsUp className="text-white text-xl" />,
      name: "Best Equipment",
      description:
        "Advanced, High-quality, Innovative, Reliable, State-of-the-art",
    },
  ];
  const textSlides = [
    {
      image: slide1,
    },
    {
      image: slide2,
    },
    {
      image: slide3,
    },
  ];
  const [slideIndex, setSlideIndex] = useState(0);

  useEffect(() => {
    const slideInterval = setInterval(() => {
      setSlideIndex((index) => {
        return index > textSlides.length - 2 ? 0 : index + 1;
      });
    }, 6000);

    return () => clearInterval(slideInterval);
  }, [slideIndex, textSlides.length]);
  return (
    <section>
      <div className="md:relative">
        <div className="">
          {textSlides?.map((_, index) => {
            return (
              <Fragment key={index}>
                <div
                  className={`${
                    index === slideIndex
                      ? "block animate-fadeIn absolute w-full md:items-center md:justify-center object-contain h-full overflow-hidden"
                      : "hidden"
                  }`}
                >
                  <div className="flex items-center md:h-[640px] h-[400px] justify-center">
                    <img
                      loading="lazy"
                      src={textSlides[index]?.image}
                      alt="Banner description"
                      className="w-full h-full z-0 opacity-90 object-cover"
                    />
                  </div>
                </div>
              </Fragment>
            );
          })}
          <div className="md:abosulte">
            <img src={banner} alt="Banner description" className="w-full h-[50%] opacity-100" />
            <div className="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-start p-8 md:pl-20">
          <div className="md:w-[800px]">
            <h1 className="text-blue-800 text-3xl md:text-[48px] leading-tight font-bold">
              BUILDING MAINTENANCE
            </h1>
            <div className="flex space-x-3 items-center text-gray-50 font-extralight mt-4">
              <p className="text-blue-800 text-ls font-bold">Home</p>
              <FaChevronRight color="blue" />
              <p className="text-blue-800 text-ls font-bold">Services</p>
              <FaChevronRight color="blue" />
              <p className="text-blue-800 text-ls font-bold">
                Building Maintenance
              </p>
            </div>
          </div>
        </div>
          </div>
        </div>
      </div>
      {/* <div className="relative">
        <img
          src={banner}
          alt="Banner description"
          className="w-full h-auto md:h-[500px] object-cover opacity-60"
        />
        <div className="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-start p-8 md:pl-20">
          <div className="md:w-[800px]">
            <h1 className="text-blue-800 text-3xl md:text-[48px] leading-tight font-bold">
              BUILDING MAINTENANCE
            </h1>
            <div className="flex space-x-3 items-center text-gray-50 font-extralight mt-4">
              <p className="text-blue-800 text-ls font-bold">Home</p>
              <FaChevronRight color="blue" />
              <p className="text-blue-800 text-ls font-bold">Services</p>
              <FaChevronRight color="blue" />
              <p className="text-blue-800 text-ls font-bold">
                Building Maintenance
              </p>
            </div>
          </div>
        </div>
      </div> */}

      <div className="flex flex-col md:flex-row md:p-20 md:space-x-20">
        <div className="flex flex-col  md:ml-20 space-y-5 py-7 md:w-[40%] w-[100%] p-5 md:p-0">
         
          {/* <div className="w-full border-2 border-black h-[400px] rounded-xl service-img relative">
            <div className="w-full h-full bg-indigo-900 opacity-70 rounded-xl absolute flex flex-col items-center justify-center"></div>
            <div className="w-full h-full rounded-xl absolute flex flex-col items-center justify-center p-16"></div>
          </div> */}
          <BookingForm />
        </div>
        <div className="md:w-[60%] md:mr-40 space-y-4 p-5 me:p-0">
          <img
            className="md:h-[485px] md:w-[100%]"
            src={cleaninservice}
            alt=""
          />
          <h1 className="md:text-2xl font-bold">Building Maintenance</h1>
          <div className="md:pr-44">
            <p className="text-base">
              At Modello Services, we specialize in providing a full spectrum of
              building maintenance (renovation services) tailored to meet the
              diverse needs of your property. Our dedicated team of experienced
              professionals are committed to ensuring that your building remains
              in optimal condition, combining functionality with aesthetic
              appeal. 
            </p>
         
          </div>
          <div class="flex flex-col md:flex-row">
            <div class="w-full md:w-1/2">
              <img class="w-full h-auto object-cover" src={hover} alt="" />
            </div>
            <div class="w-full md:w-1/2 p-5">
              <div className="h-full w-full flex flex-col space-y-5 p-5">
                <h1 className="font bold text-2xl">
                 From routine inspections of:
                </h1>
                <div className="flex flex-col space-y-5">
                  <div className="flex space-x-2 items-center ">
                    <IoCheckmarkCircle />
                    <p>HVAC system maintenance</p>
                  </div>
                  <div className="flex space-x-2 items-center ">
                    <IoCheckmarkCircle />
                    <p>Electrical Engineering</p>
                  </div>
                  <div className="flex space-x-2 items-center ">
                    <IoCheckmarkCircle />
                    <p>Plumbing Engineering</p>
                  </div>
                  <div className="flex space-x-2 items-center ">
                    <IoCheckmarkCircle />
                    <p>Painting & Tiling Services</p>
                  </div>
                  <div className="flex space-x-2 items-center ">
                    <IoCheckmarkCircle />
                    <p>Steel & Metal Fabrication</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="md:pr-44">
            <p>
              Our skilled technicians are well-versed in addressing a broad
              range of maintenance requirements. We understand the intricacies
              of maintaining a building and as such our services are designed to
              offer efficient and effective solutions. Recognizing that each
              building has unique maintenance needs, we provide personalized
              solutions to address specific requirements. Our approach ensures
              that your property receives the attention it deserves, promoting
              longevity and minimizing potential issues. Modello Services
              understand that maintenance issues can arise unexpectedly. That is
              why our team is available to provide prompt and reliable emergency
              support offering you peace of mind in critical situations. Whether
              you manage a commercial, residential, or industrial property,
              Modello Services is your reliable partner for comprehensive
              building maintenance. Contact us today to discuss your specific
              needs and let us contribute to the optimal condition and
              performance of your property.
            </p>
          </div>
          <div className="grid md:grid-cols-2 w-full place-items-center md:gap-10 gap-2">
            {serviceicons.map((item, index) => {
              return (
                <div key={index} className="w-full">
                  <div className="flex flex-row items-center justify-center w-full py-5 space-x-5">
                    <div className="w-[10%] ">
                      <div className="w-10 h-10 rounded-full bg-[#F19C4F] flex items-center justify-center">
                        {" "}
                        {item?.icon}
                      </div>
                    </div>
                    <div className="w-[90%] flex flex-col">
                      <p className="font-bold">{item?.name}</p>
                      <p className="text-xs">{item?.description}</p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
}

export default BuildingMaintenance;

import { Outlet, useLocation } from "react-router-dom";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import { useState, useEffect } from "react";

const Layout = () => {
  const [scroll, setScroll] = useState(true);
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <div>
      <Navbar setScroll={setScroll} />
      <div>{scroll && <Outlet />}</div>
      {scroll && <Footer />}
    </div>
  );
};

export default Layout;

import React, { useState } from "react";
import banner from "../../assets/images/industrial1.jpeg";
import hover from "../../assets/images/industrial.jpg";
import cleaninservice from "../../assets/images/tipo-di-sporco-per-lavare-pavimenti-industriali-con-lavasciua-pavimenti.webp";
import visionLogo from "../../assets/icons/eye.svg";
import teamholder from "../../assets/icons/teamholder.svg";
import builderLogo from "../../assets/icons/builder.svg";
import { FaChevronRight } from "react-icons/fa";
import { IoCheckmarkCircle } from "react-icons/io5";
import { GrUserSettings } from "react-icons/gr";
import { GiDelicatePerfume } from "react-icons/gi";
import { FaStudiovinari } from "react-icons/fa6";
import { FaThumbsUp } from "react-icons/fa";
import serviceBg from "../../assets/images/disinfection-worker-spraying-subway-staircase-due-covid19-pandemic.webp";
import { Link } from "react-router-dom";
import { TiChevronRight } from "react-icons/ti";
import BookingForm from "../../components/booking_form";

function IndustrialCleaning() {
  const serviceicons = [
    {
      icon: <GrUserSettings className="text-white text-xl" />,
      name: "Experienced Staff",
      description: "Skilled, Knowledgeable, Professional, Efficient, Reliable",
    },
    {
      icon: <GiDelicatePerfume className="text-white text-xl" />,
      name: "Fast Service ",
      description: "Quick, Efficient, Prompt, Expedient, Timely",
    },
    {
      icon: <FaStudiovinari className="text-white text-xl" />,
      name: "Natural products ",
      description: "Eco-friendly, Safe, Organic, Non-toxic, Sustainable",
    },
    {
      icon: <FaThumbsUp className="text-white text-xl" />,
      name: "Best Equipment",
      description:
        "Advanced, High-quality, Innovative, Reliable, State-of-the-art",
    },
  ];
  return (
    <section>
      <div className="relative">
        <img
          src={banner}
          alt="Banner description"
          className="w-full h-auto md:h-[500px] object-cover opacity-60"
        />
        <div className="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-start p-8 md:pl-20">
          <div className="md:w-[800px]">
            <h1 className="text-blue-800 text-3xl md:text-[48px] leading-tight font-bold">
              INDUSTRIAL CLEANING
            </h1>
            <div className="flex space-x-3 items-center text-gray-50 font-extralight mt-4">
              <p className="text-blue-800 text-ls font-bold">Home</p>
              <FaChevronRight color="blue" />
              <p className="text-blue-800 text-ls font-bold">Services</p>
              <FaChevronRight color="blue" />
              <p className="text-blue-800 text-ls font-bold">
                Industrial Cleaning
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col md:flex-row md:p-20 md:space-x-20 w-full">
        <div className="flex flex-col  md:ml-20 space-y-5 py-7 md:w-[40%] w-[100%] p-5 md:p-0">
          

          <BookingForm />
        </div>
        <div className="md:w-[60%] md:mr-40 space-y-4 p-5 me:p-0">
          <img
            className="md:h-[500px] md:w-[90%]"
            src={cleaninservice}
            alt=""
          />
          <h1 className="md:text-2xl font-bold">MODE OF OPERATIONS</h1>
          <p className="md:pr-44">
            Whenever a customer requests for our cleaning or any of our building
            maintenance services, an initial deposit si going to be paid upfront
            and this covers site visits, assessment, inspection and quote
            provision. The client will pay this fee when an agreement has been
            made for a team to visit the site. It is worth noting that this
            initial deposit shall be part of the final quote presented after the
            site visit. The time for presenting the quote however varies but
            takes a minimum of 24 hours maximum of 72 hours. This initial
            deposit is largely dependent on the location of the customer and the
            prices for that are expounded below
          </p>
          <div class="flex flex-col md:flex-row">
            <div class="w-full md:w-1/2">
              <img class="w-full h-auto object-cover" src={hover} alt="" />
            </div>
            <div class="w-full md:w-1/2 p-5">
              <div className="h-full w-full flex flex-col space-y-5 p-5">
                <h1 className="font bold text-2xl">
                  Our team prefers doing it with perfection.
                </h1>
                <div className="flex flex-col space-y-5">
                  <div className="flex space-x-2 items-center ">
                    <IoCheckmarkCircle />
                    <p>Preparation and Safety Measures</p>
                  </div>
                  <div className="flex space-x-2 items-center ">
                    <IoCheckmarkCircle />
                    <p>Dusting and Removing Debris</p>
                  </div>
                  <div className="flex space-x-2 items-center ">
                    <IoCheckmarkCircle />
                    <p>Cleaning with Detergents or Cleaning Agents</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="md:pr-44">
      <p className="text-base">
        Industrial cleaning involves the cleaning and maintenance of large
        commercial or industrial spaces, often requiring specialized equipment,
        techniques, and trained personnel due to the scale and complexity of the
        job.
      </p>
      <ul className="list-disc pl-8 mt-4 text-base">
        <li>
          <span className="font-bold">Specialization:</span> Offering expertise
          in specific industries or unique cleaning methods tailored to
          different industrial needs.
        </li>
        <li>
          <span className="font-bold">Technology and Innovation:</span>
          Implementing advanced cleaning technologies and eco-friendly practices
          can set a company apart.
        </li>
        <li>
          <span className="font-bold">Customized Solutions:</span> Providing
          tailored cleaning plans based on the unique requirements of each
          client.
        </li>
        <li>
          <span className="font-bold">Trained Staff:</span> Emphasizing the
          expertise and training of the cleaning crew to ensure quality and
          reliability.
        </li>
        <li>
          <span className="font-bold">Safety Measures:</span> Highlighting
          stringent safety protocols and compliance with industry regulations can
          build trust and credibility.
        </li>
      </ul>
      <p className="mt-4">
        By excelling in these areas, a cleaning company can stand out among its
        competitors in the industrial cleaning sector.
      </p>
    </div>
          <div className="grid md:grid-cols-2 w-full place-items-center md:gap-10 gap-2">
            {serviceicons.map((item, index) => {
              return (
                <div key={index} className="w-full">
                  <div className="flex flex-row items-center justify-center w-full py-5 space-x-5">
                    <div className="w-[10%] ">
                      <div className="w-10 h-10 rounded-full bg-[#F19C4F] flex items-center justify-center">
                        {" "}
                        {item?.icon}
                      </div>
                    </div>
                    <div className="w-[90%] flex flex-col">
                      <p className="font-bold">{item?.name}</p>
                      <p className="text-xs">{item?.description}</p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
}

export default IndustrialCleaning;
